import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"

class BlogsPost extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      NoOfPost: 6,
    }
    this.handleScroll = this.handleScroll.bind(this)
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
  }

  handleScroll = () => {
    var lastScrollY = window.pageYOffset + 1100

    if (lastScrollY > window.outerHeight) {
      var count = this.state.NoOfPost + 3
      this.setState({
        NoOfPost: count,
      })
    }
  }

  render() {
    const { data } = this.props
    const { NoOfPost } = this.state

    return (
      <React.Fragment>
        <h1 className="blog-header">Blogs</h1>
        <div className="blog-yellow-line" />
        <ul className="blog-list" onScroll={this.onScrollEvent}>
          {data.data.allContentfulBlogs.edges.slice(0, NoOfPost).map(items => (
            <li>
              <div className="post-item template-square columned">
                <div className="post-thumbnail">
                  <Img sizes={items?.node?.featureImage?.fluid} />
                </div>
                <div className="post-details">
                  <h6 className="post-title">
                    <Link to={`/${items?.node?.slug}`}>{items?.node?.title}</Link>
                  </h6>
                  <p className="blog-excerpt">
                    {items?.node?.description?.childMarkdownRemark?.excerpt}
                  </p>
                  <div className="view-post-container">
                    <Link to={`/${items?.node?.slug}`}>
                      <button className="view-post-button">View Post</button>
                    </Link>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </React.Fragment>
    )
  }
}

const Blogs = data => (
  <React.Fragment>
    <SEO title="Blogs" keywords={[`gatsby`, `application`, `react`]} />
    <section id="blogs" className="blog-section">
      <div className="container blog-page">
        <BlogsPost data={data}></BlogsPost>
      </div>
    </section>
  </React.Fragment>
)

export default Blogs

export const query = graphql`
  query BlogsQuery {
    allContentfulBlogs(sort: {order: DESC, fields: [publicData]}) {
      edges {
        node {
          id
          title
          slug
          publicData(formatString: "MMMM D, YYYY")
          author {
            name
            photo {
              fluid(maxWidth: 350) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
              }
            }
          }
          description {
            childMarkdownRemark {
              excerpt(pruneLength: 250)
            }
          }
          featureImage {
            fluid(maxWidth: 1120, quality: 100) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
        }
      }
    }
  }
`
